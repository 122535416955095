import NeoSetStatus from "@/containers/set-status";
import {mapGetters} from "vuex";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import NeoTags from "@/components/tags";
import axios from "@/axios";
import NeoRestoreBtn from "@/components/restore-btn";
import NeoCardNotes from "@/containers/card-notes";
import {EventBus} from "@/main.js";

export default {
    name: "key-facts",
    components: {
        NeoSetStatus,
        NeoInput,
        NeoButton,
        NeoTags,
        NeoRestoreBtn,
        NeoCardNotes,
    },
    props: {
        content: Object,
        setStatus: Boolean,
        restore: Boolean,
        moreInfo: Boolean,
        tab: String,
        showNotes: Boolean,
        ghostCard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            controls: {
                status: this.content.status,
            },
            tempKeyFactsHolder: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode"]),
        flags() {
            return this.content.risk_categories.map((tag) => ({tag}));
        },
    },
    mounted() {},
    methods: {
        onStatChanges(status) {
            this.controls.status = status;
            this.$emit("dataChanges", {...this.content, status});
        },
        startEdit() {
            this.$store.dispatch("setSelectedEntityData", {
                datatype: "key_fact",
                all_data: this.content,
                editEntity: true,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");
        },
        cancelSave() {
            this.content.editMode = false;
            this.content.key_fact_name = this.tempKeyFactsHolder;
            this.$forceUpdate();
        },
        async completeSave() {
            let content = {...this.content, id: this.content._id};
            delete content._id;
            await axios.put(`/key-facts`, content).then((response) => {});
            EventBus.$emit("refreshData");
            this.content.editMode = false;
            this.$forceUpdate();
        },
        popIrrelevantData() {
            delete this.content.confidence_score;
            this.$emit("dataChanges", {...this.content, status: null, tab: this.tab});
        },
        handleKeyFactsCopy() {
            EventBus.$emit("handleCopyToTable", {tab: this.tab, list: [this.content]});
        },
        openMoreInfo() {
            EventBus.$emit("more", this.content);
        },
        onAddKeyFact() {
            this.$store.dispatch("setSelectedEntityData", {
                all_data: this.content,
                datatype: "key_fact",
                editEntity: false,
                text: "",
                userDefined: false,
            });

            this.$store.dispatch("showAddEntityPanel");
        },
        onRagChanges(ragstatus) {
            this.$emit("dataChanges", {...this.content, entity_status: ragstatus});
        },
        onTagChanges(categories) {
            let cats = categories.map((e) => e.tag);
            this.$emit("dataChanges", {...this.content, risk_categories: cats});
        },
    },
};
