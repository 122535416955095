<template>
    <div class="tw-w-full">
        <template v-if="getOutputTemplates.length > 0">
            <div class="key-facts-tab">
                <div v-if="showNav" class="key-facts-tab__left-tab-wrapper">
                    <div class="tw-pb-3 tw-mx-2 tw-mb-1 tw-font-medium tw-text-lg tw-text-gray-600">Categories</div>
                    <ul class="key-facts-tab__left-tab-wrapper__left-tabs">
                        <li v-for="category in getOutputTemplates" :key="category.category_id" @click="setActiveCategory(category)" :class="{active: activeCategory && category.category_id === activeCategory.category_id}" v-text="category.category_name" />
                    </ul>
                </div>
                <div ref="tab-container" class="key-facts-tab__tab-container" :style="[{'grid-column-start': showNav ? '2' : '1'}, showNav && {padding: '0px 20px 0px 20px'}]">
                    <div class="content tw-gap-x-8" :style="{'grid-template-columns': showNav ? '' : 'repeat(auto-fit, minmax(23rem, 1fr))'}">
                        <div class="tw-mb-8 section__header">
                            <div class="tw-pb-3 tw-font-medium tw-text-lg tw-text-gray-600">Sections</div>
                            <template v-if="activeCategory && activeCategory.sections && activeCategory.sections.length > 0">
                                <div v-for="section in activeCategory.sections" :key="section.section_name">
                                    <div class="tw-flex tw-items-center tw-text-lg tw-justify-between tw-border-0 tw-border-b-2 tw-border-gray-300 tw-border-solid tw-pb-3 tw-pt-3">
                                        <div class="tw-flex tw-space-x-3 tw-items-start">
                                            <span v-text="section.section_name" />
                                        </div>
                                    </div>
                                    <div v-if="keyFacts(section.section_id).length > 0" class="cards tw-gap-12">
                                        <NeoKeyFacts v-for="keyFact in keyFacts(section.section_id)" :key="keyFact._id" :content="keyFact" :showNotes="showNotes" :moreInfo="moreInfo" :setStatus="setStatus" :tab="tab" :ghostCard="keyFact.ghost_card" @dataChanges="onDataChanges(keyFact, $event, tab)" />
                                    </div>
                                    <div v-else class="tw-flex tw-px-4 tw-pt-4 tw-justify-center tw-text-lg tw-text-gray-500">
                                        No Data Found
                                    </div>
                                </div>
                            </template>
                            <div v-else class="tw-flex tw-px-4 tw-pt-4 tw-justify-center tw-text-lg tw-text-gray-500">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div id="message" class="tw-pt-28 tw-w-full tw-flex tw-flex-col tw-items-center">
                <div class="tw-mb-3">
                    <img class="tw-h-16" src="@/assets/icons/ico-cloud-data.svg" alt="cloud" />
                </div>
                <div class="tw-text-base tw-text-gray-500">Nothing here! Let's explore...</div>
            </div>
        </template>
    </div>
</template>

<script>
import NeoKeyFacts from "@/containers/cards/key-facts";
import NeoToggle from "@/components/toggle";
import {mapGetters} from "vuex";

export default {
    name: "neo-key-facts-tab",
    components: {
        NeoKeyFacts,
        NeoToggle,
    },
    props: {
        content: Array,
        showNav: Boolean,
        showNotes: {
            type: Boolean,
            default: false,
        },
        entities: {
            type: Array,
            default: () => [],
        },
        moreInfo: Boolean,
        setStatus: Boolean,
        tab: String,
    },
    data: () => ({
        activeCategory: null,
        categories: null,
    }),
    computed: {
        ...mapGetters(["getEntitiesRelationsAttributesData", "getReadOnlyMode", "getOutputTemplates"]),
        ...mapGetters("outputTemplates", ["cachedProductsCategoriesMap"]),
    },
    async mounted() {
        await this.initTemplateOutputProduct();
    },
    methods: {
        onDataChanges(card, changes, tab) {
            this.$emit("dataChanges", {card, changes, tab});
        },
        initTemplateOutputProduct() {
            const componenInstance = this;
            if (componenInstance.getOutputTemplates?.length > 0) {
                componenInstance.setActiveCategory(componenInstance.getOutputTemplates[0]);
            } else {
                return new Promise((res) => {
                    const unwatchGetProduct = this.$watch("getOutputTemplates", () => {
                        if (componenInstance.getOutputTemplates?.length > 0) {
                            componenInstance.setActiveCategory(componenInstance.getOutputTemplates[0]);
                            unwatchGetProduct();
                            res();
                        }
                    });
                });
            }
        },
        setActiveCategory(category) {
            category.sections = category.sections.filter((sec) => sec.active);
            this.activeCategory = category;
            this.$refs["tab-container"]?.scrollIntoView(true);
        },
        keyFacts(id) {
            let keyFactsData = this.content.filter((con) => con.output_category_id === this.activeCategory?.category_id && con.output_section_id === id);
            if (!this.getReadOnlyMode) {
                keyFactsData.push(this.ghostCardKeyFacts(id));
            }
            return keyFactsData;
        },
        ghostCardKeyFacts(section_id) {
            let obj = {
                status: null,
                tab: this.tab,
                output_category_id: this.activeCategory?.category_id ?? null,
                output_section_id: section_id,
                risk_categories: [],
                ghost_card: true,
            };
            return obj;
        },
    },
};
</script>

<style lang="scss" scoped>
.key-facts-tab {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    position: relative;

    &__left-tab-wrapper {
        position: sticky;
        top: 85px;
        left: 0;
        align-self: flex-start;

        &__left-tabs {
            list-style-type: none;
            padding-left: 0px;
            padding-right: 10px;
            position: sticky;
            top: 0;
            width: 180px;
            height: 350px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-corner {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #a8b5c9;
                border-radius: 20rem;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            li {
                border-right: 2px solid #ccc;
                padding: 10px;
                cursor: pointer;
                &:hover,
                &.active {
                    border-right: 2px solid #0e68d5;
                    background-color: #e6f0fb;
                }
            }
        }
    }

    &__tab-container {
        grid-column-start: 2;
        grid-column-end: 12;
        padding: 0px 0px 0px 30px;
        min-height: 360px;
        overflow: auto;
        h3 {
            border-bottom: 1px solid #ccc;
            padding-bottom: 15px;
            margin-left: 10px;
            margin-right: 10px;
        }
        .tabs {
            width: 100%;
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
        // column-gap: 0rem;
        grid-area: cards;

        .empty--content {
            margin-left: 10px;
        }

        .section__header {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
    .intro {
        grid-area: intro;
        position: sticky;
        padding: 2rem 0;

        .more-info {
            height: 7rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .emails {
            .items {
                display: flex;
                flex-wrap: wrap;
                margin: 1.5rem 0;
                color: var(--brand-color);
                h4 {
                    margin: 0;
                    margin-right: 1rem;
                }
            }
        }
    }

    .extras {
        grid-area: extras;
    }

    h2 {
        margin: 0.5rem 0;
    }
    .field {
        color: var(--text-fade-color);
        margin: 0.5rem 0;
    }
    .div-h {
        width: 100%;
        height: 5px;
        border-bottom: 2px solid var(--brand-accent-area-hover-on);
    }

    .div-v {
        height: 70%;
        width: 5px;
        border-right: 2px solid var(--brand-accent-area-hover-on);
    }
    .cards {
        grid-area: cards;
        width: 100%;
        display: grid;
        place-content: baseline;
        position: relative;
        margin: 1.5rem 0;
        grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
        #message {
            img {
                width: 3.6rem;
                opacity: 0.72;
            }
        }
    }
}
</style>
